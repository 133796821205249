import React, {useState, useEffect} from 'react';
import { getPriceByStation } from '../../helpers/price';
import iconCup from '../../images/app/icon_cup.png';
import iconTumbler from '../../images/app/icon_tumbler.png';
import VolumeItem from './VolumeItem';

const WaterOption = (props) => {
  const cup = {
    id: 0,
    volume: 200,
    price: getPriceByStation(props.stationCode, "cup"),
    discountedPrice: null,
    icon: iconCup,
  };

  const tumbler = {
    id: 1,
    volume: 500,
    price: getPriceByStation(props.stationCode, "tumbler"),
    discountedPrice: null,
    icon: iconTumbler,
  };

  const [waterItem, setWaterItem] = useState(1);
  const {onUpdateWaterOption} = props;

  const onSetWaterItem = (id) => {
    setWaterItem(id);
  };

  useEffect(() => {
    if (waterItem === 0) {
      onUpdateWaterOption(cup);
    } else {
      onUpdateWaterOption(tumbler);
    }
    // eslint-disable-next-line
  }, [waterItem])

  return (
    // eslint-disable-next-line max-len
    <div className={`d-flex flex-column align-items-center w-100 ` + props.className}>
      <p className="water-option-title">{props.title}</p>
      <div className="d-flex justify-content-center mb-2">
        <VolumeItem isFree={props.isFree} currentWaterItem={waterItem} {...cup} setWaterItem={onSetWaterItem} containerClassName="mx-2" />
        <VolumeItem isFree={props.isFree} currentWaterItem={waterItem} {...tumbler} setWaterItem={onSetWaterItem} containerClassName="mx-2" />
      </div>
    </div>
  );
};
export default WaterOption;
