import React, {useState, useEffect} from 'react';
import chillIcon from '../../images/app/chill.png';
import hotIcon from '../../images/app/hot.png';
import warmIcon from '../../images/app/warm.png';
import {TempEnum} from './../../helpers/constants';
import TempItem from './TempItem';

const TempOption = (props) => {
  const hot = {
    name: TempEnum.HOT,
    icon: hotIcon,
    description: 'I\'m Hot!',
  };

  const warm = {
    name: TempEnum.WARM,
    icon: warmIcon,
    description: 'Normal',
  };

  const chill = {
    name: TempEnum.CHILL,
    icon: chillIcon,
    description: 'Let’s Chill',
  };

  const [tempSelected, setTemp] = useState(TempEnum.WARM);
  const {onUpdateTempOption} = props;

  const onSetTemp = (tempSelected) => {
    setTemp(tempSelected);
  };

  useEffect(() => {
    if (tempSelected === TempEnum.HOT) {
      onUpdateTempOption(hot);
    } else if (tempSelected === TempEnum.WARM) {
      onUpdateTempOption(warm);
    } else {
      onUpdateTempOption(chill);
    }
    // eslint-disable-next-line
    }, [tempSelected])

  return (
    <div className={`d-flex flex-column align-items-center w-100 ` + props.className}>
      <p className="temp-option-title">{props.title}</p>
      <div className="d-flex justify-content-center">
        <TempItem {...hot} setTemp={onSetTemp} containerClassName="mx-2" currentTemp={tempSelected} />
        <TempItem {...warm} setTemp={onSetTemp} containerClassName="mx-2" currentTemp={tempSelected} />
        <TempItem {...chill} setTemp={onSetTemp} containerClassName="mx-2" currentTemp={tempSelected} />
      </div>
    </div>
  );
};
export default TempOption;
