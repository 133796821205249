import React from 'react';

const VolumeItem = (props) => {
  const {id, volume, price, icon, containerClassName,
    setWaterItem, currentWaterItem} = props;

  const handleOnClick = () => {
    setWaterItem(id);
  };

  return (
    <div onClick={handleOnClick} className="d-flex flex-column volume-item"> {/* eslint-disable-line */}
      <div className={`d-flex flex-column volume-item-container mb-1 mt-1 ${containerClassName} ` + (currentWaterItem === id ? 'volume-item-container-active' : '')}>
        <div className="volume-item-volume ">{`${volume} ml`}</div>
        <img className="align-self-center volume-item-img" alt="tumbler type" src={icon}></img>

        {props.isFree?
         <div alt="price number" className={`mt-1 volume-item-price--small align-self-center ${currentWaterItem === id ? 'text-info' : ''}`}><strike>Rp {price}</strike> Free! </div> :
         <div alt="price number" className={`mt-1 align-self-center ${currentWaterItem === id ? 'text-info' : ''}`}>Rp {price}</div>
        }

      </div>
    </div>
  );
};
export default VolumeItem;
