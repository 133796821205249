const STATION_PRICE_DATA = [
  {
    name: 'sman3bdg',
    prices: {
      cup: 1000,
      tumbler: 2000,
    },
  },
  {
    name: 'sman8jkt',
    prices: {
      cup: 1000,
      tumbler: 2000,
    },
  },
  {
    name: 'neduh',
    prices: {
      cup: 2000,
      tumbler: 4000,
    },
  },
  {
    name: 'test-free',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'izifill',
    prices: {
      cup: 3000,
      tumbler: 5000,
    },
  },
  {
    name: 'workcoffeebdg',
    prices: {
      cup: 5000,
      tumbler: 10000,
    },
  },
  {
    name: 'workcoffeejkt',
    prices: {
      cup: 5000,
      tumbler: 10000,
    },
  },
  {
    name: 'workcoffeebks',
    prices: {
      cup: 5000,
      tumbler: 10000,
    },
  },
  {
    name: 'dibawahtangga',
    prices: {
      cup: 5000,
      tumbler: 10000,
    },
  },
  {
    name: 'noplastic',
    prices: {
      cup: 3000,
      tumbler: 5000,
    },
  },
  {
    name: 'urbanforest',
    prices: {
      cup: 2500,
      tumbler: 5000,
    },
  },
  {
    name: 'fantastik',
    prices: {
      cup: 3000,
      tumbler: 5000,
    },
  },
  {
    name: 'bluebird1',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'bluebird2',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'udjo',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'smkn3denpasar',
    prices: {
      cup: 1500,
      tumbler: 3000,
    },
  },
  {
    name: 'ecotourism',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'circulareconomy',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'ikn1',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'pnb',
    prices: {
      cup: 750,
      tumbler: 1500,
    },
  },
  {
    name: 'ikn2',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'biologi-ui',
    prices: {
      cup: 1000,
      tumbler: 2000,
    },
  },
  {
    name: 'psc_wwf',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'itb1',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'itb2',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'aqua_wwf1',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'aqua_wwf2',
    prices: {
      cup: 0,
      tumbler: 0,
    },
  },
  {
    name: 'kedaiutama',
    prices: {
      cup: 1000,
      tumbler: 2000,
    },
  },
  {
    name: 'smk_merdeka',
    prices: {
      cup: 1000,
      tumbler: 2000,
    },
  },
  {
    name: 'default',
    prices: {
      cup: 2000,
      tumbler: 4000,
    },
  },
];


export const getPriceByStation = (stationCode, type) => {
  let priceData = STATION_PRICE_DATA.find((a) => a.name === stationCode);
  if (!priceData) priceData = STATION_PRICE_DATA.find((a) => a.name === 'default');

  if (type === 'cup') return priceData.prices.cup;
  if (type === 'tumbler') return priceData.prices.tumbler;
};
