import React, {useEffect, useState} from 'react';
import LoginPopup from '../../components/app/LoginPopup';
import TempOption from '../../components/app/TempOption';
import WaterOption from '../../components/app/WaterOption';
import Button from '../../components/Button';
import {getByFetch, poll, post} from '../../helpers/api';
import {fetchBonusRegister} from '../../helpers/commons';
import {
  DEMO_STATIONS,
  GALON_BASED_STATIONS,
  IZIFILL_NAME_DEFAULT_VALUE,
  STATION_CODE_KEY,
  UNAUTHORIZED_STATUS_CODE,
  UNLIMITED_REFILL_STATIONS
} from '../../helpers/constants';
import {defaultTemp, defaultWaterOption} from '../../helpers/default';
import {
  FREE_FILL_API_URL, GALON_AVAILABLE, GET_USER_BONUS_TOPUP_AVAILABLE, GET_USER_URL,
  IS_STATION_ONLINE, ORDER_API_URL,
} from '../../helpers/endpoints';
import {getItemFromStorage} from '../../helpers/localStorage';
import {navigateToFill, navigateToRefill, navigateToTopup} from '../../helpers/navigation';

const Order = () => {
  const stationCode = getItemFromStorage(STATION_CODE_KEY);
  if (!stationCode) {
    navigateToRefill();
  }

  const [waterOption, setWaterOption] = useState(defaultWaterOption);
  const [temp, setTemp] = useState(defaultTemp);
  const [showLoginContainer, setShowLoginContainer] = useState(false);
  const [freeFillNumber, setFillFreeNumber] = useState(0);
  const [bonusRegister, setBonusRegister] = useState(0);
  const [name, setName] = useState(IZIFILL_NAME_DEFAULT_VALUE);
  const [email, setEmail] = useState('');
  // const [izipay, setIzipay] = useState('');
  const [errorOrder, setErrorOrder] = useState('');
  const [isStationOnline, setStationOnline] = useState(false);
  const [isGalonAvailable, setGalonAvailable] = useState(true);
  const [isTopupBonusAvailable, setTopupBonusAvaibility] = useState(false);

  let isOrdered = false;

  async function fetchFreeWaterNumber() {
    const response = await getByFetch(FREE_FILL_API_URL + `/${stationCode}`);
    const data = await response.json();
    setFillFreeNumber(data.freeRemaining);
  }

  const pollStationStatus = () => {
    poll(checkStationStatus, 21000, 7000, setOfflineStation).then(function() {
      console.log('station is online!');
      setStationOnline(true);
    });
  };

  const setOfflineStation = () => {
    setStationOnline(false);
  };

  let isOnline = false;
  function checkStationStatus() {
    const url = IS_STATION_ONLINE + stationCode;
    getByFetch(url)
        .then((response) => response.json())
        .then((data) => {
          setStationOnline(data);
          if (data === true) {
            isOnline = true;
          }
        });

    return isOnline;
  }

  const pollGalonStatus = () => {
    poll(checkGalonState, 21000, 7000, setGalonEmpty).then(function() {
      console.log('galon is available!');
      setGalonAvailable(true);
    });
  };

  const setGalonEmpty = () => {
    setGalonAvailable(false);
  };

  let isGalonAvailableState = false;
  function checkGalonState() {
    const url = GALON_AVAILABLE + stationCode;
    getByFetch(url)
        .then((response) => response.json())
        .then((data) => {
          setGalonAvailable(data);
          if (data === true) {
            isGalonAvailableState = true;
          }
        });

    return isGalonAvailableState;
  }

  async function fetchTopupAvaibility() {
    const response = await getByFetch(GET_USER_BONUS_TOPUP_AVAILABLE);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setTopupBonusAvaibility(data);
    }
  }

  async function fetchUserData() {
    const response = await getByFetch(GET_USER_URL);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setName(data.name);
      setEmail(data.email);
    }
  }

  const updateWaterOption = (waterOption) => {
    setWaterOption(waterOption);
  };

  const onLoginSuccess = () => {
  };

  const onContainerClicked = () => {
    if (showLoginContainer) setShowLoginContainer(false);
  };

  const updateTemp = (temp) => {
    setTemp(temp);
  };

  async function postOrderRequest() {
    if (isOrdered) return;
    if (!isStationGalonAvailable()) return;
    if (!isStationOnline && !DEMO_STATIONS.includes(stationCode)) return;

    if (DEMO_STATIONS.includes(stationCode)) {
      navigateToFill({
        stationCode: stationCode,
        waterOption: waterOption,
        temp: temp,
      });
      return;
    }

    const shouldShowLoginPopup =
      !showLoginContainer && process.env.PROMPT_LOGIN === 'true' &&
      !isLoggedIn() && freeFillNumber <= 0;

    if (shouldShowLoginPopup) {
      setShowLoginContainer(true);
    } else {
      isOrdered = true;
      const response = await post(ORDER_API_URL, {
        volumeId: waterOption.id,
        temp: temp.name,
        waterStationId: stationCode,
        userId: email,
        price: (freeFillNumber > 0 && !isLoggedIn()) ? 0 : waterOption.price,
      });
      if (response.ok) {
        navigateToFill({
          stationCode: stationCode,
          waterOption: waterOption,
          temp: temp,
        });
      } else {
        isOrdered = false;
        setErrorOrder(response);
      }
    }
  }

  async function onTopupButton() {
    navigateToTopup({
      stationCode: stationCode,
      waterOption: waterOption,
      temp: temp,
    });
  }


  function isLoggedIn() {
    return name !== IZIFILL_NAME_DEFAULT_VALUE;
  }

  function isFree() {
    return freeFillNumber > 0 && !isLoggedIn();
  }

  function isStationAvailable() {
    return isStationOnline || DEMO_STATIONS.includes(stationCode);
  }

  function isStationGalonAvailable() {
    if (GALON_BASED_STATIONS.includes(stationCode)) {
      return isGalonAvailable;
    } else return true;
  }

  useEffect(() => {
    async function getBonusRegister() {
      setBonusRegister(await fetchBonusRegister());
    }

    fetchFreeWaterNumber();
    fetchUserData();
    pollStationStatus();
    pollGalonStatus();
    fetchTopupAvaibility();
    getBonusRegister();
  }, []);

  return (
    <div className="d-flex flex-column" onClick={onContainerClicked}>
      <p className="mt-7 mb-0 align-self-center">Welcome to Station: <span id='stationCode' className="text-info">{stationCode}</span> <span className={isStationAvailable() ? 'text-info' : 'text-danger'}>{isStationAvailable() ? '(online)' : '(offline)'}</span></p>
      {!isStationGalonAvailable()  && 
        <p className="my-0 align-self-center">
          <span className={isGalonAvailable ? 'text-info' : 'text-danger'}>{isGalonAvailable ? 'Water Available' : 'Gallon is empty! Contact employee to replace the gallon.'}</span>
        </p>
      }
      <WaterOption
        stationCode={stationCode}
        isFree={isFree()}
        onUpdateWaterOption={updateWaterOption}
        title={`Hi ` + name + `, How Thirsty Are You?`} />
      <TempOption
        onUpdateTempOption={updateTemp}
        className="mt-2" title="How Hot Do You Want It?" />

      <div className={`text-sm text-center mx-2 align-self-center ${errorOrder === '' ? 'd-none' : 'd-block text-danger'}`}>
        Uh oh, your IziPay is not sufficient to refill, let`s Top Up now
      </div>

      <div
        className="py-1 d-flex flex-column align-items-center order-summary">
        <h6>ORDER SUMMARY</h6>

        {isFree() ?
          <p className="my-0">{` ${waterOption.volume} ml ${temp.name} `}<strike>Rp{waterOption && waterOption.price}</strike> This time our Treat! </p> :
          <p className="my-0">{` ${waterOption.volume} ml ${temp.name} Water for just Rp ${waterOption && waterOption.price}`}</p>
        }
        {!isLoggedIn() && ((UNLIMITED_REFILL_STATIONS.includes(stationCode) ) ? 
        <p className="my-0">Please Sign In to Order </p> :
        <p className="my-0">Free Fill Remaining: <span className="text-danger">{freeFillNumber}</span> </p> )}
      </div>

      {!errorOrder ?
        <Button onClick={postOrderRequest} className="mt-2 d-flex justify-content-center" text="ORDER" buttonClassName={(isStationAvailable() && isStationGalonAvailable()) ? 'button-secondary' : 'button-disabled'} /> :
        <Button onClick={onTopupButton} className="mt-2 d-flex justify-content-center" text="TOP UP" buttonClassName="button-secondary" />
      }


      {!isLoggedIn() ?
        <>
          <p onClick={() => setShowLoginContainer(true)}
            className="mt-1 ta-center" >
            <u>Sign in / Register</u>
          </p>

          {!UNLIMITED_REFILL_STATIONS.includes(stationCode) && 
          <p className="mt-0 ta-center text-info">&#9432; Get Rp{(bonusRegister).toLocaleString('en-US')} after you register</p>
          }
        </> :
        isTopupBonusAvailable &&
        <>
          <p className="mt-1 ta-center text-info">&#9432; Keep Hydrated! Get a one-time 100% cashback for your top up until 31 October 2022</p>
        </>
      }

      <LoginPopup bonusRegister={bonusRegister} onLoginSuccess={onLoginSuccess} showFreeQuotaText={freeFillNumber <= 0} stationCode={stationCode} waterOption={waterOption} temp={temp} className={`order-login__container ${showLoginContainer ? 'd-block' : 'd-none'}`} />

    </div>
  );
};
export default Order;
