import {TempEnum} from './constants';
import chillIcon from '../images/app/chill.png';

export const defaultWaterOption = {
  id: 0,
  volume: 0,
  price: 0,
  discountedPrice: null,
};

export const defaultTemp = {
  name: TempEnum.CHILL,
  icon: chillIcon,
};
