import React from 'react';

const TempItem = (props) => {
  const {name, icon, description, setTemp, currentTemp, containerClassName} = props;

  const handleOnClick = () => {
    setTemp(name);
  };

  return (
    <div onClick={handleOnClick} className="d-flex flex-column temp-item">  {/* eslint-disable-line */}
      <div className={`d-flex flex-column justify-content-center py-1 temp-item-container ${containerClassName} ` + (currentTemp === name ? 'temp-item-container-active' : '')}>
        <img className="align-self-center temp-item-img" alt="tumbler type" src={icon}></img>
      </div>
      <p className={`mt-1 align-self-center ${currentTemp === name ? 'text-info temp-item-text-active' : ''}`}>{description}</p>
    </div>
  );
};
export default TempItem;
